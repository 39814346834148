import { template as template_4a16e87c8e364597b416eeb114ee1b04 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_4a16e87c8e364597b416eeb114ee1b04(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
